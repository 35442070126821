<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">Create Task</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="task.name.en"
            label="Name (English)"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <v-text-field
            v-model="task.points"
            label="Points"
            type="number"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <v-text-field
            v-model="task.loi"
            label="Length of Survey"
            type="number"
            :rules="[rules.required]"
            required
          ></v-text-field>
          <v-select
            v-model="task.taskType"
            :items="taskTypes"
            label="Task Type"
            :rules="[rules.required]"
            required
          ></v-select>
          <v-textarea
            v-model="task.description"
            label="Description"
            :rules="[rules.required]"
            required
          ></v-textarea>
          <v-select
            v-model="task.status"
            :items="statuses"
            label="Status"
            item-text="text"
            item-value="value"
            :rules="[rules.required]"
            required
          ></v-select>
          <v-select
            v-model="task.renderType"
            :items="renderTypes"
            label="Render Type"
            :rules="[rules.required]"
            required
          ></v-select>
          <v-text-field
            v-if="task.renderType === 'ifeed'"
            v-model="task.surveyUrl"
            label="Ifeed URL"
            :rules="[rules.required, rules.url]"
            required
          ></v-text-field>

          <v-btn primary @click="submit" :disabled="!valid">Submit</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";

const service = new RestResource();
export default {
  data() {
    return {
      valid: false,
      task: {
        points: "",
        loi: "",
        name: {
          en: "",
        },
        taskType: "",
        description: "",
        status: "",
        renderType: "",
        surveyUrl: "",
      },
      taskTypes: ["survey", "quiz"],
      statuses: [
        {
          text: "Active",
          value: "A",
        },
        {
          text: "Inactive",
          value: "I",
        },
      ],
      renderTypes: ["ifeed", "app", "alchemer"],
      rules: {
        required: (value) => !!value || "Required.",
        url: (value) => {
          const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return pattern.test(value) || "Invalid URL.";
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.createTask(this.task).then(() => {
          this.$router.push({ name: "MobileV2Tasks" });
        });
        this.$disableLoader();
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
